<script setup lang="ts">
import { Logger } from "@/logger";
import {report, type SECTION } from "@/states/report";
const props = defineProps<{
  sectionId: SECTION;
}>();
function addEmpty() {
  Logger.info("AddConstatationBtn.vue : Adding empty constatations")
  report.addConstatation({
    section: props.sectionId,
    description: "",
    num: "",
    comment: "",
    mark: "1",
    security: false,
    photos: [],
  });
}
</script>
<template>
  <li class="list-group-item py-3">
    <div class="d-flex w-100 justify-content-center">
      <button class="btn btn-primary" @click="addEmpty">
        <i class="bi bi-plus"></i>
      </button>
    </div>
  </li>
</template>
