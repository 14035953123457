<script setup lang="ts">
import { computed } from "vue";
import ConstatationList from "./ConstatationList.vue";
import { IQOA_MARK, SECTION, sections, report } from "@/states/report";
import { getWorstMark } from "@/catalogues/item";
import { sectionTitles } from "@/docx/export.config"

const marks =  computed<Map<SECTION, IQOA_MARK>>(() => {
  const map = new Map<SECTION, IQOA_MARK>();
  for(const section of sections) {
    const sectionConstatations = report.filterBySection(section);
    const sectionMarks = sectionConstatations.map((constatation) => constatation.mark);
    const sectionMark = getWorstMark(sectionMarks);
    map.set(section, sectionMark);
  }
  return map;
});

</script>
<template>
  <div class="d-flex flex-column align-items-stretch sticky-top">
    <div class="list-group list-group-flush border-bottom">
      <div v-for="section in sections" class="list-group list-group-flush border-bottom">
        <a href="#" class="list-group-item list-group-item-action py-3 lh-sm" data-bs-toggle="collapse"
          :data-bs-target="'#collapse-zone-' + section">
          <strong>{{ sectionTitles.get(section) }}</strong>
          <span class="badge text-bg-primary ms-1">{{ marks.get(section) }}</span>
        </a>
        <div class="collapse show" :id="'collapse-zone-' + section">
          <ConstatationList :sectionId="section" />
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.scrollarea {
  overflow-y: scroll;
}
</style>

