import { IQOA_MARK, IQOA_MARK_S, SECTION } from "@/states/report";

export interface OldReportExport {
  voie: string;
  sens: string;
  BIS: string;
  PR: string;
  latDeb: string;
  longDeb: string;
  latFin: string;
  longFin: string;
  autoroute: string;
  vueGmaps: string; // ID of the file in OneDrive
  vueGen: string; // ID of the file in OneDrive
  datePrecInspection: string;
  materiau: string;
  fonctionnement: string;
  typeMur: string;
  procede: string;
  fondations: string;
  longueur: string;
  epaisseur: string;
  hauteurMini: string;
  hauteurMaxi: string;
  surfaceVisibleCalc: string;
  fruit: string;
  district: string;
  commune: string;
  positionVoie: string;
  distanceVoie: string;
  acces: string;
  oldReport : {
    cotAbords: string;
    cotAbordsS: string;
    cotDrain: string;
    cotDrainS: string;
    cotStruct: string;
    cotStructS: string;
    cotEquip: string;
    cotEquipS: string;
    cot: string;
    cotS: string;
    concGen: string;
    concSecu: string;
    concStruc: string;
    concDrain: string;
    concEquip: string;
    sugSecu: string;
    sugCour: string;
    sugSpe: string;
    sugInv: string;
    sugSurv: string;
  };
}

export interface ReportExport extends OldReportExport {
  nom: string;
  vuePrincipaleUrl: string; // ID of the file in OneDrive
  dateInspection: string;
  meteo: string;
  temperature: string;
  partiesNonVisitees: string;
  moyens: string;
  cot: string;
  cotS: string;
  concGen: string;
  concSecu: string;
  concStruc: string;
  concDrain: string;
  concEquip: string;
  sugSecu: string;
  sugCour: string;
  sugSpe: string;
  sugInv: string;
  sugSurv: string;
  photos : PhotoExport[];
  constatations : SectionExport[];
}

export interface  PhotoExport {
  id: string;
  remoteId: string;
  downloadUrl: string;
  ordre: string;
  localisation: string;
  commentaire: string;
}

export interface ConstatationExport {
  num: string;
  description: string;
  classe: string;
  securite: string;
  comment: string;
  photos: string;
  evolution: string;
}

export interface SectionExport {
  section: SECTION;
  titre: string;
  mark: IQOA_MARK;
  security: IQOA_MARK_S;
  constatations: ConstatationExport[];
}

export const sectionTitles = new Map<SECTION, string>([
  ["ZONE", "Abords de l'ouvrage"],
  ["EQUIP", "Equipements et éléments de protection"],
  ["DRAIN", "Drainage"],
  ["STRUCT", "Structure"],
]);
