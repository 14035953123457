<script setup lang="ts">
import { Catalogue, catalogues } from '@/catalogues/catalogue';
import {report} from "@/states/report";

const emit = defineEmits(['change'])
function addCatalogue(catalogue: Catalogue) {
  if(!report.selectedCatalogs.some(item => item.num === catalogue.num)) {
    report.selectedCatalogs.push(catalogue);
  }
  emit('change');
}

function removeCatalogue(catalogue: Catalogue) {
  const index = report.selectedCatalogs.findIndex((item) => item.num === catalogue.num);
  if(index != -1) {
    report.selectedCatalogs.splice(index,1);
  }
  emit('change');
}
</script>
<template>
  <div class="dropdown">
    <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
      Catalogues
    </button>
    <ul class="dropdown-menu">
      <li v-for="catalogue in catalogues"><a @click="addCatalogue(catalogue)" class="dropdown-item" href="#">{{
        catalogue.num + " : " + catalogue.description }}</a></li>
    </ul>
    <span v-for="catalogue in report.selectedCatalogs" class="badge text-bg-secondary rounded-pill m-1">
      <i class="bi bi-x" @click="removeCatalogue(catalogue)"></i>
      {{ catalogue.num + " : " + catalogue.description }}
    </span>
  </div>
</template>
