import { Logger } from "@/logger";
import { reactive } from "vue";
export interface Catalogue {
  num: number;
  description: string;
}

export const catalogues = reactive<Catalogue[]>([]);

// Look for the catalogues whose num match indexes and return the array of catalog
export function getCatalogues(ids: number[]): Catalogue[] {
  const matches = [];
  for(let id of ids) {
    // catalogue 1 and 2 are the same
    if(id === 2) {
      id = 1
    }
    const match = catalogues.find((catalogue) => catalogue.num === id)

    if(match) {
      matches.push(match);
    } else {
      Logger.warn(`catalogue.ts : could not find catalogue ${id} in catalogues`);
    }
  }
  return matches;
}


/*[
  {
    num: 3,
    description: "Murs poids en béton armé",
  },
  {
    num: 6,
    description: "Murs poids sur semelle encastrée",
  }
];*/
