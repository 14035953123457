<script setup lang="ts">
import CatalogueToggler from "./CatalogueToggler.vue";
import { computed, ref } from "vue";
import { getSectionInReport } from "@/catalogues/section";
import { report} from "@/states/report";
import { Logger } from "@/logger";
import { type CatalogueItem, cataloguesItems, getMarkInReport } from "@/catalogues/item";

const filteredItems = ref<CatalogueItem[]>([]);
const query = ref<string | undefined>();

function search() {
  if(query.value) {
    const str = query.value?.toLowerCase();
    filteredItems.value = selectedCatalogItems.value.filter((item) => {
      try {
        const isInNum = item.num.toString().startsWith(str);
        const isInTitle = item.title.toLowerCase().includes(str);
        const isInDescription = item.description.toLowerCase().includes(str);
        const isInSubDescription = item.subDescription.toLowerCase().includes(str);
        return isInNum || isInDescription || isInSubDescription || isInTitle;
      } catch (error) {
        Logger.error(`CatalogueModal.vue : there was a problem filtering ${JSON.stringify(item)}`);
      }
    });
  } else {
    filteredItems.value = selectedCatalogItems.value.filter((item) => true)
  }
}

function addConstatation(item: CatalogueItem) {
  let section = getSectionInReport(item.sectionId);
  let mark = getMarkInReport(item.mark);
  Logger.info(`CatalogueComponent.vue : adding constatation ${JSON.stringify(item)} in ${section} with ${mark} mark`)
  if (section) {
    report.addConstatation({
      section: section,
      description: item.title,
      mark: mark,
      comment: "",
      num: item.num.toString(),
      security: false,
      photos: [],
    });
    query.value = undefined;
  }
}

const selectedCatalogItems = computed(() => {
  return cataloguesItems.filter((item) => {
    const isInSelectedCatalog = report.selectedCatalogs.some((catalog) => catalog.num == item.catalogueId);
    return isInSelectedCatalog;
  });
});

const sortedItems = computed<Map<number, CatalogueItem[]>>(() => {
  const sortedItems: Map<number, CatalogueItem[]> = new Map();
  for(const item of filteredItems.value) {
    let sectionItems = sortedItems.get(item.sectionId);
    if(sectionItems) {
      sectionItems.push(item);
    } else {
      sectionItems = [item];
      sortedItems.set(item.sectionId, sectionItems);
    }
  }
  return sortedItems;
});

</script>

<template>
  <div class="d-flex flex-column w-100 gap-2">
    <div class="d-inline-flex align-items-center justify-content-between">
      <input ref="input" v-model="query" @input="search" @focusin="search" type="text" class="form-control" style="max-width: 500px;"
      placeholder="Rechercher dans le catalogue"/>
    </div>
    <div>
      <CatalogueToggler @change="search" />
    </div>
    <div v-for="sectionIdx in sortedItems.keys()">
      <ul class="list-group" :class="{ show: filteredItems.length }">
      <li v-for="item in sortedItems.get(sectionIdx)" class="list-group-item list-group-item-action" @click="addConstatation(item)">
          <div class="row">
            <div class="col-1">
              {{ item.num }}
            </div>
            <div class="col-10">
              <div class="fw-bold"><small>{{ item.title }}</small></div>
              <div><small class="fw-bold text-body-tertiary">{{ item.part + " - " + item.subPart }}</small></div>
              <div><small>{{ item.description }}</small></div>
              <div><small>{{ item.subDescription }}</small></div>
            </div>
            <div class="col-1">
              <small>{{ item.mark }}</small>
            </div>
          </div>
      </li>
    </ul>
    </div>

  </div>
</template>
