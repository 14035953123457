<script setup lang="ts">
// FIX ME : find a way to properly type Route Location with app routes
// Code is messy here. We need to find a way to parse route properly
// to display a list of link to pages in hierarchy

import { onMounted, ref } from 'vue';
import { RouteLocation, RouterLink, useRoute, useRouter } from 'vue-router';
import { getOuvrage } from '@/xlsx/database';
const route = useRoute();
const router = useRouter();

// add a different name to display, for instance the "ouvrage" name for a report
const breadcrumbs = ref<(RouteLocation & {displayName: string})[]>([]);

onMounted(() => {
// split the path
const pathArray = route.path.split("/");

// retrieve each route of the hierarchy
while( pathArray.length ) {
  if(pathArray.length === 1) pathArray.push("");
  let path = pathArray.join('/');
  const location: RouteLocation & {displayName: string} = {
    ...router.resolve(path),
    displayName: "",
  };
  // set displayname depending on location.
  // FIX ME : Could we do it better ?
  if(location.name === "home") {
    location.displayName = "Accueil";
    pathArray.pop();
    pathArray.pop();
  } else if (
    location.name === "report"
    && location.params.inspectionid
    && typeof location.params.inspectionid ==="string"
  ) {
    const ouvrageName = getOuvrage(location.params.inspectionid).name;
    location.displayName = `Rapport ${ouvrageName}`;
    pathArray.pop();
    pathArray.pop();
  } else {
    pathArray.pop();
  }

  // Add to breadcrumbs to display
  breadcrumbs.value?.push(location);
}
breadcrumbs.value.reverse();
});
</script>
<template>
  <ol class="breadcrumb" style="margin-bottom: 0px;">
    <li v-for="crumb in breadcrumbs" class="breadcrumb-item">
      <RouterLink :to="crumb.path">{{ crumb.displayName }}</RouterLink>
    </li>
  </ol>
</template>
