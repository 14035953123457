import { Inspection } from "@/xlsx/database";
import { ensureScope, getToken } from "./auth";
import * as MicrosoftGraph from "@microsoft/microsoft-graph-client";

const DATABASE_ID = "01TAJWVXVANDINFTE7XVA2DEAVM6UE6LBI";
const DESORDRES_FOLDER = "01TAJWVXSBEJWM3ZLACBDLAHYSS3LWCNNF";
const VUES_FOLDER = "01TAJWVXSHQ4LXQWFAKFEKN24OESOES4IE";
const EXTRACT_FOLDER = "01TAJWVXQKHAWGI7FNWBD3KQMDFXG6BOJB";
const EXTRACT_2024_FOLDER = "01TAJWVXQXPS2MWODKUNAZYF5YZPKE3ITS";
const CATALOGUE_ID = "01TAJWVXRKWYL72DTPKJDLN3U36BPEVIDQ";
const TEMPLATE_FOLDER = "01TAJWVXXSNB3SRQCP25CK4HXICDVCJL2B";
const TEMPLATE_URL_IQOA = "01TAJWVXTRVY2ODJ46SBCZ5KJXRPRUCOKL";
const TEMPLATE_URL_IDP = "01TAJWVXXBFELVGUQ22VBZQPDK6ZBUV2MX";

interface GraphFile {
  id: string;
  name: string;
  "@microsoft.graph.downloadUrl": string;
}

let fileDesordres: GraphFile[];
let fileVues: GraphFile[];

const authProvider = {
    getAccessToken: async () => {
        // Call getToken in auth.js
        return await getToken();
    }
};

let user = {
  id: "",
  displayName: "",
};
// Initialize the Graph client
const graphClient = MicrosoftGraph.Client.initWithMiddleware({ authProvider });
export async function getUser() {
  ensureScope('user.read');
  user = await graphClient.api("/me").select(["id","displayName"]).get();
  return user;
}

// Returns the list files and folders in a folder
export async function getFiles(id: string) {
  ensureScope('files.read');
  try {
    const response = await graphClient
      .api(`/me/drive/items/${id}/children`)
      .select("id,name,content.downloadUrl")
      .top(10000)
      .get();
    return response.value;
  } catch (error) {
    console.error(error);
  }
}

export async function getRootFolder() {
  ensureScope('files.read');
  try {
    const response = await graphClient
      .api('/me/drive/root/children')
      .get();
    return response.value;
  } catch (error) {
    console.error(error);
  }
}

export async function getDatabaseUrl(): Promise<string | undefined> {
  try {
    return getFileUrl(DATABASE_ID);
  } catch (error) {
    console.error(error);
  }
}

export async function getFileUrl(id: string): Promise<string | undefined> {
  ensureScope('files.read');
  try {
    const response = await graphClient
        .api(`/me/drive/items/${id}`)
        .select('@microsoft.graph.downloadUrl')
        .get();
    const downloadUrl = response["@microsoft.graph.downloadUrl"];
    return downloadUrl;
  } catch (error) {
    console.error(error);
  }
}

// Returns the file object with this id
export async function getFile(id: string) {
  ensureScope('files.read');
  try {
    const response = await graphClient
        .api(`/me/drive/items/${id}`)
        .get();
    return response;
  } catch (error) {
    console.error(error);
  }
}

export async function getDatabaseFile() {
  return getFile(DATABASE_ID);
}

export async function getCatalogueFile() {
  return getFile(CATALOGUE_ID);
}

let isFileLoaded = false;

export async function loadFiles() {
  if(isFileLoaded) return;
  await getFiles(DESORDRES_FOLDER).then((result: GraphFile[]) => fileDesordres = result)
  await getFiles(VUES_FOLDER).then((result: GraphFile[]) => fileVues = result);
  isFileLoaded = true;
}

export async function getFilesDesordres() {
  return fileDesordres;
}

export async function getFilesVues() {
  return fileVues;
}

// Get file Object, Fetch content, return blob
async function downloadFile(id: string): Promise<Blob> {
  const url = await getFileUrl(id);
  if(!url) throw new Error(`graph.ts : There was a problem fetching file ${id}`);
  const response = await fetch(url, {
    method: "GET",
  });
  return response.blob();
}

export async function downloadTemplate(type: Inspection["type"]): Promise<Blob>  {
  if(type === "IQOA") {
    return downloadFile(TEMPLATE_URL_IQOA);
  } else {
    return downloadFile(TEMPLATE_URL_IDP);
  }
}

async function getElementInChildrenByName(folderId: string, name: string) {
  const items: {name: string, id: string}[] = await getFiles(folderId);
  return items.find((item) => item.name === name);
}
// returns the ID of the figure located in the EXTRACT_FOLDER where pdf extract are stored
export async function getFigureFromExtract(path: string): Promise<string | undefined> {
  const filename = path.substring(path.lastIndexOf('/') + 1);
  let foldername = path.substring(0, path.lastIndexOf('/'));
  foldername = foldername.replace("/figures","");
  foldername = foldername.substring(foldername.lastIndexOf('/') + 1);
  const folder = await getElementInChildrenByName(EXTRACT_2024_FOLDER, foldername);
  if(!folder) return undefined;
  const figureFolder = await getElementInChildrenByName(folder.id, "figures");
  if(!figureFolder) return undefined;
  const figure = await getElementInChildrenByName(figureFolder.id, filename);
  return figure?.id;
}
