<script setup lang="ts">
import ConstatationMenu from "@/components/constatations/ConstatationMenu.vue";
import ConstatationsMenuToggler from "@/components/constatations/ConstatationsMenuToggler.vue";
import PhotoGallery from "@/components/photos/PhotoGallery.vue";
import CatalogueModal from "@/components/catalogue/CatalogueModal.vue";
import { getFileUrl, getFilesDesordres, getFilesVues } from "@/microsoft/graph";
import { getDesordresByInspection, getVuesByInspection } from '@/xlsx/database';
import { onBeforeMount, onBeforeUnmount, ref } from "vue";
import { report } from "@/states/report";
import SearchBar from "@/components/constatations/SearchBar.vue";
import Breadcrumbs from "@/components/navigation/Breadcrumbs.vue";
import GenerateReportBtn from "@/components/constatations/GenerateReportBtn.vue";
import { getOuvrage } from "@/xlsx/database";
import { getCatalogues } from "@/catalogues/catalogue";

const props = defineProps<{
  inspectionid: string;
}>();

const showConstatationsMenu = ref<boolean>(true);
const galleryColumnCount = ref<number>(1);


function toggleMenu() {
  showConstatationsMenu.value = !showConstatationsMenu.value;
  if (showConstatationsMenu) {
    galleryColumnCount.value = 1;
  } else {
    galleryColumnCount.value = 2;
  }
}

async function loadFromRemote() {
  // Look for data in xlsx
  const desordres = getDesordresByInspection(props.inspectionid);
  const vues = getVuesByInspection(props.inspectionid);

  // get photo data of files in folders
  const fileDesordres = await getFilesDesordres();
  const fileVues = await getFilesVues();
  for(const desordre of desordres) {
    for(const file of fileDesordres) {
      if (file.name == desordre.photoUrl) {
        desordre.downloadUrl = await getFileUrl(file.id) || "";
        desordre.remoteId = file.id;
        break;
      }
    }
  }

  for(const vue of vues) {
    for(const file of fileVues) {
      if (file.name == vue.photoUrl) {
        vue.downloadUrl = await getFileUrl(file.id) || "";
        vue.remoteId = file.id;
        break;
      }
    }
  }

  report.inspectionId = props.inspectionid;
  report.constatations = [];
  report.selectedCatalogs = [];
  report.photos.push(...vues, ...desordres)
}

function autoSelectCatalogue() {
  const ouvrage = getOuvrage(report.inspectionId);
  const catalogues = getCatalogues(ouvrage.type);
  report.selectedCatalogs = catalogues;
}

onBeforeMount(async () => {
  const loaded = report.loadFromStorage(props.inspectionid);
  if(!loaded) {
    await loadFromRemote();
  }
  autoSelectCatalogue();
});

onBeforeUnmount(() => {
  report.reset();
});
</script>
<template>

  <div class="d-flex align-items-center justify-content-between bg-white border-bottom p-2 sticky-top">
    <div class="d-flex align-items-center">
      <Breadcrumbs />
    </div>
    <div class="d-flex gap-2 align-items-center justify-content-center">
      <SearchBar :inspectionid="props.inspectionid" />
      <GenerateReportBtn class="z-10" />
    </div>
    <div class="d-flex align-items-center justify-content-center me-2">
      <ConstatationsMenuToggler class="z-100" @toggle="toggleMenu" :show-menu="showConstatationsMenu" />
    </div>
  </div>
  <div class="z-0 container-fluid position-relative">
    <div class="row">
      <div class="col column">
        <PhotoGallery :inspectionid="props.inspectionid" :column-count="galleryColumnCount" />
      </div>
      <div v-if="showConstatationsMenu" class="col column">
        <ConstatationMenu />
      </div>
    </div>
  </div>
  <CatalogueModal />
</template>
<style scoped>
.column {
  height: 100vh;
  overflow-y: auto;
}
</style>
