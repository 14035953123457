<script setup lang="ts">
import { provide, ref } from 'vue';
import { LoaderData, LoaderSymbol } from './loader.type';

const active = ref<boolean>(false);
const message = ref<string>("");

function toggle() {
  active.value = !active.value;
}

function reset() {
  active.value = false;
  message.value = "";
}

provide<LoaderData>(LoaderSymbol, {
  active,
  message,
  toggle,
  reset,
});
</script>
<template>
<div
    class="spinner-container bg-white bg-opacity-50 fixed-top w-100 h-100 d-flex justify-content-center align-items-center"
    :class="{ 'd-none': !active }"
  >
  <div class="spinner-card bg-opacity-100 bg-light d-flex justify-content-center align-items-center rounded">
    <div class="spinner-border text-secondary me-1" role="status">
      <span class="visually-hidden">{{ message }}</span>
    </div>
    <span class="text-secondary">{{ message }}</span>
  </div>
</div>
<slot></slot>
</template>
<style scoped>
.spinner-container {
  z-index: 100;
  /* Disable touch action to avoid zooming when loading a Canvas */
  touch-action: none;
}

.spinner-card {
  padding: 20px;
}
</style>
