import { createApp } from "vue";
import router from "./router";
import App from "./App.vue";
import { Logger } from "./logger";

const app = createApp(App);

Logger.init(app);

app.use(router);

app.mount('#app');
