import { ConstatationExport, PhotoExport, ReportExport, SectionExport, sectionTitles } from "./export.config";
import * as DOCX from 'docx-templates';
import { downloadTemplate, getFile } from "@/microsoft/graph";
import { Report, Constatation, sections } from "@/states/report";
import { Inspection, getBlankOldReportExport, getInspection, getInspections, getOldReportExport, getOuvrage } from "@/xlsx/database";
import { Logger } from "@/logger";
import { getWorstMark, getWorstMarkS } from "@/catalogues/item";

const EMPTY_REPLACER = "$$";

export async function generateReport(reportExport: ReportExport, type: Inspection["type"]): Promise<Blob> {
    try {
        const template = await downloadTemplate(type);
        const docx = await DOCX.createReport({
            template: new Uint8Array(await template.arrayBuffer()),
            data: reportExport,
            failFast: false,
            noSandbox: true, // has to do this in browser
            //processLineBreaks: true,
            additionalJsContext: {
                injectImage: async function (maxWidth: number, maxHeight: number, id: string) {
                    const file = await getFile(id);
                    // Browser should already have downloaded images in galery,
                    // there may be better options than re-downloading them
                    const response = await fetch(file["@microsoft.graph.downloadUrl"]);
                    const buffer = await response.arrayBuffer();
                    let sizes: {width: number, height: number};
                    if(file.image.width && file.image.height) {
                        sizes = getImgResize(file.image.width, file.image.height, maxWidth, maxHeight)
                    } else {
                        const { width, height }= await getImageSizeFromArrayBuffer(buffer);
                        sizes = getImgResize(width, height, maxWidth, maxHeight);
                    }

                    return { width: sizes.width, height: sizes.height, data: buffer, extension: ".jpg" };

                },
            },
            errorHandler(e, command_code) {
                console.error(`There was a problem generating report for ${reportExport.nom} command is ${e.message,command_code}\n`, reportExport);
                return EMPTY_REPLACER;
            }
        });
        return new Blob([docx]);
    } catch (err) {
        console.log(err)
        throw err;
    }
}

export async function exportReportData(report: Report): Promise<ReportExport> {
    const photoExports: PhotoExport[] = report.photos.map((photo) => {
        return {
            id: photo.id,
            remoteId: photo.remoteId,
            downloadUrl: photo.downloadUrl,
            ordre: photo.order.toString(),
            localisation: photo.localization + ", " + photo.part,
            commentaire: photo.comment,
        }
    });

    const partExports: SectionExport[] = [];
    for(const section of sections) {
        const constatations = report.filterBySection(section);
        const marks = constatations.map((constatation) => constatation.mark);
        const marksS = constatations.map((constatation) => constatation.security);
        partExports.push({
            titre: sectionTitles.get(section) || "",
            section: section,
            mark: getWorstMark(marks),
            security: getWorstMarkS(marksS),
            constatations: exportConstatation(constatations,photoExports),
        })

    }
    let oldReportExport;
    try {
        oldReportExport = await getOldReportExport(report.inspectionId);
    } catch(e) {
        Logger.error("generate.ts : could not export old report data"); // There should be a user warning or smth here
        console.log(e);
        oldReportExport = getBlankOldReportExport();
    }
    const ouvrage = getOuvrage(report.inspectionId);
    const inspection = getInspection(report.inspectionId);
    // replace blanks by "$$" to see them in docx
    updateEmptyValues(ouvrage);
    updateEmptyValues(oldReportExport);
    updateEmptyValues(inspection);

    return {
        ...oldReportExport,
        nom: ouvrage.name,
        dateInspection: capitalizeFirstLetter(inspection.dateFormated),
        meteo: inspection.meteo,
        temperature: inspection.temperature,
        moyens: inspection.moyens,
        partiesNonVisitees: inspection.partiesNonVisitees,
        vuePrincipaleUrl: photoExports[0].remoteId,
        cot: "",
        cotS: "",
        concGen: "",
        concSecu: "",
        concStruc: "",
        concDrain: "",
        concEquip: "",
        sugSecu: "",
        sugCour: "",
        sugSpe: "",
        sugInv: "",
        sugSurv: "",
        photos: photoExports,
        constatations: partExports,
    }
}

/**
 * Export the constatation as it should be in order to insert in the report
 * @param constatations
 * @param photoExports
 * @returns
 */
function exportConstatation(constatations: Constatation[], photoExports: PhotoExport[]): ConstatationExport[] {
    const exports = constatations.map((constatation) => {
        let photoLabels: string[] = [];
        photoExports.forEach((photoExport) => {
            if(constatation.photos.some((photo)=> photo.order.toString() === photoExport.ordre)) photoLabels.push(photoExport.ordre);
        })
        const label = photoLabels.length ? photoLabels.join(", ") : "";
        return {
            num: constatation.num,
            description: constatation.description,
            classe: constatation.mark,
            securite: constatation.security ? "OUI" : "NON",
            comment: constatation.comment,
            evolution: "NON",
            photos: label
        }
    })
    return exports;
}

// Function to update empty string values to "$$" recursively
function updateEmptyValues(obj: any): void {
  // Iterate through the object's properties
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      if (typeof value === "string" && value === "") {
        obj[key] = "$$";
      } else if (typeof value === "object" && value !== null) {
        updateEmptyValues(value); // Recursively update nested objects
      }
    }
  }
}

function getImageSizeFromArrayBuffer(arrayBuffer: ArrayBuffer): Promise<{ width: number, height: number }> {
    return new Promise((resolve, reject) => {
        // Convert ArrayBuffer to Blob
        const blob = new Blob([arrayBuffer], { type: 'image/jpeg' }); // Change MIME type accordingly

        // Create Object URL
        const url = URL.createObjectURL(blob);

        // Create an Image element
        const img = new Image();

        // Set up the onload event to get the image dimensions
        img.onload = () => {
            // Resolve the promise with the image dimensions
            resolve({ width: img.width, height: img.height });

            // Revoke the Object URL to free up memory
            URL.revokeObjectURL(url);
        };

        // Set up the onerror event to handle any errors
        img.onerror = (error) => {
            // Revoke the Object URL to free up memory
            URL.revokeObjectURL(url);

            // Reject the promise with the error
            reject(error);
        };

        // Set the source of the image to the Object URL
        img.src = url;
    });
}

function getImgResize(w0: number, h0: number, maxWidth: number, maxHeight: number ) {
    const imgRatio = w0 / h0;
    const BBratio = maxWidth / maxHeight;
    let width = 1;
    let height = 1;
    if (imgRatio <= BBratio) {
        height = maxHeight;
        width = height * imgRatio;
    } else {
        width = maxWidth;
        height = width / imgRatio;
    }
    return {width: width, height: height};
}

function capitalizeFirstLetter(string: string) {
  if (string.length === 0) {
    return string;
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
}
