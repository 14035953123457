<script setup lang="ts">
import { signIn } from '@/microsoft/auth';
import { loadCataloguesfromXlsx } from "@/catalogues/loader"
import { fetchAndProcessXlsx } from "@/xlsx/database";
import { computed, onBeforeMount, onMounted } from 'vue';
import Breadcrumbs from "@/components/navigation/Breadcrumbs.vue";
import { Ouvrage, Inspection, getInspections, getOuvrage } from "@/xlsx/database";
import { inspectionList } from '@/states/inspectionList';
import Header from '@/components/inspections/Header.vue';
import { loadFiles } from "@/microsoft/graph";
import router from '@/router';
import { inject } from 'vue';
import { LoaderSymbol } from '@/components/shared/loader.type';

const loader = inject(LoaderSymbol);

function open(id: string) {
  router.push({name: "report", params: { inspectionid: id }});
}

async function load() {
  if(loader) {
    loader.message.value = "Chargement de vos données";
    loader.active.value = true;
  }
  await fetchAndProcessXlsx();
  await loadCataloguesfromXlsx();
  await loadFiles();
  if(loader) {
    loader.message.value = "";
    loader.active.value = false;
  }
}
onBeforeMount(async () => {
  //await signIn();
})
onMounted(async () => {
  await signIn();
  await load();
  inspectionList.initialize(getInspections());
  inspectionList.filterInspections();
});

const datas = computed(()=> {
  const datas: [Inspection, Ouvrage][] = [];
  if(inspectionList) {
    for (const inspection of inspectionList.filteredInspections) {
      let ouvrage: Ouvrage;
      try {
        ouvrage = getOuvrage(inspection);
        datas.push([inspection, ouvrage]);
      } catch(e) {
        console.error(e);
      }
    }
  }

  return datas;
});
</script>
<template>
  <div class="container">
    <div class="my-2">
      <Breadcrumbs />
    </div>
    <table class="table table-striped">
      <thead class="">
        <tr>
          <th scope="col">Nom</th>
          <th scope="col">Matériau</th>
          <Header label="IDP ou IQOA" property="type" />
          <Header label="Campagne" property="campaign" />
          <th scope="col">Date</th>
          <th scope="col">Terminée ?</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="[inspection, ouvrage] in datas" @click="open(inspection.id)">
          <td>{{ ouvrage.name }}</td>
          <td>{{ ouvrage.process }}</td>
          <td>{{ inspection.type }}</td>
          <td>{{ inspection.campaign }}</td>
          <td>{{ inspection.dateFormated }}</td>
          <td>{{ inspection.done }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
