import { SECTION } from "@/states/report";
import { reactive } from "vue";

export interface CatalogueSection {
  catalogueId: number;
  description: string;
  parentId?: number; // undefined means root section
};

export const catalogueSections = reactive<CatalogueSection[]>([]);

// FIXME :we should think of how to map Catalog Item structure to report structure
export function getSectionInReport(index: number): SECTION | undefined {
  const section = catalogueSections[index];
  if(section.description.toLowerCase().includes("zone")) return "ZONE";
  else if(section.description.toLowerCase().includes("struct")) return "STRUCT";
  else if(section.description.toLowerCase().includes("quipe")) return "EQUIP";
  else if(section.description.toLowerCase().includes("drain")) return "DRAIN";
  return undefined;
}

/*[
  {
    id:"31",
    num: "1",
    description: "Zone d'influence",
    catalogueId: "3",
  },
  {
    id:"32",
    num: "2",
    description: "Equipements",
    catalogueId: "3",
  },
  {
    id:"33",
    num: "3",
    description: "Drainage",
    catalogueId: "3",
  },
  {
    id:"34",
    num: "4",
    description: "Structure",
    catalogueId: "3",
  },
  {
    id:"311",
    num: "11",
    description: "En partie supérieure",
    catalogueId: "3",
    parentId: "31"
  },
  {
    id:"312",
    num: "12",
    description: "En contrebas du mur",
    catalogueId: "3",
    parentId: "31"
  },
  {
    id:"3111",
    num: "111",
    description: "stabilité d'ensemble",
    catalogueId: "3",
    parentId: "311"
  },
  {
    id:"3112",
    num: "112",
    description: "autres défauts",
    catalogueId: "3",
    parentId: "311"
  },
  {
    id:"3121",
    num: "121",
    description: "stabilité d'ensemble",
    catalogueId: "3",
    parentId: "312"
  },
  {
    id:"3122",
    num: "122",
    description: "autres défauts",
    catalogueId: "3",
    parentId: "312"
  },
];*/
