<script setup lang="ts">
import { report, PhotoItem, type Constatation } from "@/states/report";
import { ref, getCurrentInstance } from "vue";
const instance = getCurrentInstance();
let id = ref<number>();
if (instance) id.value = instance.uid;

const props = defineProps<{
  constatation: Constatation;
}>();

const query = ref<number>();
const filteredItems = ref<PhotoItem[]>([]);

function search() {
  filteredItems.value = report.photos.filter((photo) => {
    if (photo.order && query.value) {
      return photo.order.toString().startsWith(query.value?.toString());
    } else {
      return false;
    }
  });
}

function addPhotoByOrder() {
  const photo = report.photos.find((item) => {
    return item.order == query.value;
  });

  if(photo) {
    addPhoto(photo);
  }
}

function addPhoto(photo: PhotoItem) {
  if(!props.constatation.photos.some((item) => item.order === photo.order)) {
    props.constatation.photos.push(photo);
    query.value = undefined;
    filteredItems.value = [];
  }
}

function removePhoto(photo: PhotoItem) {
  const index = props.constatation.photos.findIndex((item) => {
    return item.order == photo.order;
  });
  if(index != -1) {
    props.constatation.photos.splice(index,1);
  }
}

</script>
<template>
  <li class="list-group-item list-group-item-action py-3">
    <div class="d-flex w-100 align-items-center justify-content-between mb-1">
      <div class="me-1" :data-bs-target="'#collapse-'+id" data-bs-toggle="collapse">
        <i class="bi bi-caret-right"></i>
      </div>
      <div class="flex-grow-1 me-1">
        <div class="d-flex w-100 align-items-center justify-content-between mb-1">
          <div class="flex-grow-1 me-1">
            <input v-model="constatation.description" type="text" class="form-control" placeholder="Description" />
          </div>
          <div>
            <input v-model="constatation.num" type="text" class="form-control" placeholder="N°" />
          </div>
        </div>
        <div :id="'collapse-'+id" class="collapse show">
          <div class="w-100 align-items-center justify-content-between mb-1 mt-1">
            <input v-model="constatation.comment" type="" class="form-control mb-1" placeholder="Commentaire" />
            <div class="d-flex align-items-center">
              <div class="me-1">
                <div class="btn-group mb-1" role="group" aria-label="radio toggle button group">
                  <input v-model="constatation.mark" value="1" type="radio" class="btn-check" :name="'vbtn-radio'+id"
                    :id="'vbtn-radio1-'+id" autocomplete="off" checked>
                  <label class="btn btn-outline-secondary" :for="'vbtn-radio1-'+id">1</label>
                  <input v-model="constatation.mark" value="2" type="radio" class="btn-check" :name="'vbtn-radio' + id"
                    :id="'vbtn-radio2-'+id" autocomplete="off">
                  <label class="btn btn-outline-secondary" :for="'vbtn-radio2-'+id">2</label>
                  <input v-model="constatation.mark" value="2E" type="radio" class="btn-check" :name="'vbtn-radio' + id"
                    :id="'vbtn-radio3-'+id" autocomplete="off">
                  <label class="btn btn-outline-secondary" :for="'vbtn-radio3-'+id">2E</label>
                  <input v-model="constatation.mark" value="3" type="radio" class="btn-check" :name="'vbtn-radio' + id"
                    :id="'vbtn-radio4-'+id" autocomplete="off">
                  <label class="btn btn-outline-secondary" :for="'vbtn-radio4-'+id">3</label>
                  <input v-model="constatation.mark" value="3U" type="radio" class="btn-check" :name="'vbtn-radio' + id"
                    :id="'vbtn-radio5-'+id" autocomplete="off">
                  <label class="btn btn-outline-secondary" :for="'vbtn-radio5-'+id">3U</label>
                </div>
              </div>
              <div class="form-check ">
                <input v-model="constatation.security" class="form-check-input" type="checkbox" value=""
                  :id="'flexCheckDefault-'+id">
                <label class="form-check-label" :for="'flexCheckDefault-'+id">
                  S
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex gap-2 justify-content-start">
          <div class="">
            <input @input="search" @keyup.enter="addPhotoByOrder" v-model="query" type="number" class="form-control"
              placeholder="Recherche photo" data-bs-toggle="dropdown" aria-expanded="false">
            <ul class="dropdown-menu" :class="{ show: filteredItems.length }">
              <li @click="addPhoto(item)" v-for="item in filteredItems"><a class="dropdown-item" href="#">{{ item.order
                  }}</a></li>
            </ul>
          </div>
          <div>
            <span v-for="photo in constatation.photos" class="badge text-bg-secondary rounded-pill">
              <i @click="removePhoto(photo)" class="bi bi-x"></i>
              {{ photo.order }}
            </span>
          </div>

        </div>

      </div>
      <div class="" @click="report.deleteConstatation(constatation)">
        <i class="bi bi-trash"></i>
      </div>
    </div>
  </li>
</template>
