<script setup lang="ts">
defineProps<{
  showMenu: boolean;
}>();
const emit = defineEmits(['toggle']);
</script>
<template>
  <button @click="emit('toggle')" class="btn btn-sm btn-secondary toggle-icon">
    <span v-if="showMenu"><i class="bi bi-dash-lg "></i></span>
    <span v-else><i class="bi bi-plus-lg"></i></span>
  </button>
</template>
<style>
.toggle-icon {
  cursor: pointer;
  top: 10px;
  right: 50px;
  padding: 5px;
  border-radius: 5px;
}
</style>
