<script setup lang="ts">
import ConstatationItem from "./ConstatationItem.vue";
import AddConstatationBtn from "./AddConstatationBtn.vue";
import {report, type SECTION } from "@/states/report";
import { computed } from "vue";

const props = defineProps<{
  sectionId: SECTION;
}>();

const sectionConstatations = computed(() => {
  return report.filterBySection(props.sectionId);
});

</script>
<template>
  <div class="list-group list-group-flush border-bottom">
    <ConstatationItem v-for="constatation in sectionConstatations"  :constatation="constatation"/>
    <AddConstatationBtn :sectionId="sectionId" />
  </div>
</template>
