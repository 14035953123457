import { RouteRecordRaw, createRouter, createWebHistory } from "vue-router";
import Welcome from "./views/Welcome.vue";
import ReportView from "./views/ReportView.vue";
import PhotoGalleryView from "./views/PhotoGalleryView.vue";
import ConstatationView from "./views/ConstatationView.vue";
import CatalogueSearchView from "./views/CatalogueSearchView.vue";
import InspectionListView from "./views/InspectionListView.vue";
import { tokenExist } from "./microsoft/auth";

const routes: RouteRecordRaw[] = [
  {
    path: "/bienvenue",
    name: "welcome",
    component: Welcome,
  },
  {
    path: "/",
    name: "home",
    component: InspectionListView,
  },
  {
    path: "/report/:inspectionid?",
    name: "report",
    component: ReportView,
    props: true,
  },
  {
    path: "/report/:inspectionid/photo-gallery",
    name: "photo-gallery",
    component: PhotoGalleryView
  },
  {
    path: '/report/:inspectionid/constatations',
    name: "constatations",
    component: ConstatationView
  },
  {
    path: '/report/:inspectionid/catalogue-search',
    name: "catalogue-search",
    component: CatalogueSearchView
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

router.beforeEach(async (to) => {
  const allowedRoutesWhenLoggedOut = [
    "welcome",
  ];

  const matches = allowedRoutesWhenLoggedOut.filter(
    (route) => route === to.name
  );
  if(!tokenExist() && matches.length === 0) {
    return { name: "welcome" };
  }
});

export default router;
