import log from "loglevel";
import * as Sentry from "@sentry/vue";
import { App } from "vue";

export class Logger {
  static init(app: App) {
    // init if loglovel is info ie. most probably in production
    if (import.meta.env.DR_SENTRY_DSN != "") {
      Sentry.init({
        app,
        dsn: "https://01e997432f0bdaf4cb3c6c90dae9829d@o4506859188912128.ingest.us.sentry.io/4507447065706496",
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.replayIntegration(),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/reports.diagnosticrapid\.fr\//],
        // Session Replay
        replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      });
    }

    // set log level
    log.setLevel(import.meta.env.DR_LOG_LEVEL);
  }
  static trace(message: string) {
    log.trace(message);
  }
  static debug(...args: any) {
    log.debug(...args);
  }
  static info(message: string) {
    log.info(message);
  }
  static warn(message: string) {
    log.warn(message);
  }
  static error(message: string) {
    log.error(message);
  }
  static identify() {
    //should email
  }
}
