import { reactive, watch } from "vue";
import { v4 as uuidv4 } from "uuid";
import { Catalogue } from "@/catalogues/catalogue";
import { getFileUrl } from "@/microsoft/graph";
import { Logger } from "@/logger";
import { debounce } from "lodash";

export type IQOA_MARK_S = "S" | "";
export type IQOA_MARK = "NC" | "1" | "2" | "2E" | "3" | "3U";
export const iqoa_marks: IQOA_MARK[] = ["NC" , "1" , "2" , "2E" , "3" , "3U"];
export type SECTION = "ZONE" | "EQUIP" | "DRAIN"  | "STRUCT";
export const sections: SECTION[] = ["ZONE", "EQUIP", "DRAIN",  "STRUCT"];
// allows to control if watch callback is triggered. it is used to prevent loop with channel
let enableWatch: boolean = true;
// use BroadcastChannel to sync modification between tabs and windows
const bc = new window.BroadcastChannel("dr-reports");

// Debounce sync between tabs to prevent over calling loadFromStorage
const debouncedLoad = debounce(() => {
  report.loadFromStorage(report.inspectionId);
},
  200, // wait for 200 ms if nothing happens, load from storage.
  {
    maxWait: 1000, // wait max 1s to load from storage even though user is still adding data
  }
);
// On message from other tabs, if it is the same inspection, sync report from storage to get changes.
// We don't deal with conflicts yet
bc.onmessage = (message) => {
  if(message.data === report.inspectionId) {
    Logger.info(`report.ts : message received on channel.`)
    debouncedLoad();
  }
};

const STORAGE_REPORT_KEY = "dr-reports";

export interface PhotoItem {
    id: string;
    type: "VUE" | "DEFAULT";
    remoteId: string;
    inspectionId: string;
    photoUrl: string;
    part: string;
    localization: string;
    comment: string;
    downloadUrl: string;
    order: number;
    // Add other properties as needed
}

export interface Constatation {
  id: string;
  section: SECTION;
  description: string;
  comment: string;
  num: string;
  mark: IQOA_MARK;
  security: boolean;
  photos: PhotoItem[];
};

interface PersistedReport {
  inspectionId: string;
  constatations: Constatation[];
  photos: PhotoItem[];
}

export interface Report {
  inspectionId: string;
  constatations: Constatation[];
  photos: PhotoItem[];
  selectedCatalogs: Catalogue[]; // indexes of selected catalogue
  addConstatation: (constatation: Omit<Constatation,"id">) => void;
  deleteConstatation: (consatation: Constatation) => void;
  filterBySection: (section: SECTION) => Constatation[];
  reset: () => void;
  persist: () => void;
  loadFromStorage: (id: string) => boolean;
  refreshDownloadLinks: () => Promise<void[]>;
};

export const report = reactive<Report>({
  inspectionId: "",
  constatations: [],
  photos: [],
  selectedCatalogs: [],
  addConstatation(constatation) {
    this.constatations.push({
      id: uuidv4(),
      ...constatation
    });

  },
  deleteConstatation(constatation) {
    const index = this.constatations.findIndex(item => item.id === constatation.id);
    if(index != -1) {
      this.constatations.splice(index, 1);
    }
    else Logger.warn(`constatation.ts : could not delete constatation for ${JSON.stringify(constatation)}`);
  },
  filterBySection(section) {
    return this.constatations.filter((constatation) => {
      return constatation.section == section
    });
  },
  reset() {
    this.inspectionId = ""; // start by this to stop watching changes
    this.constatations = [];
    this.photos = [];
    this.selectedCatalogs = [];
  },
  // load data in local storage. check if inspection is already persisted
  // if so, replace it, if not, add it
  persist() {
    const data = localStorage.getItem(STORAGE_REPORT_KEY);
    let reports: PersistedReport[] = [];
    if(data) {
      reports = JSON.parse(data) as PersistedReport[];
    }
    const index = reports.findIndex((item) => item.inspectionId == this.inspectionId );
    const newVersion = {
      inspectionId: this.inspectionId,
      constatations: this.constatations,
      photos: this.photos,
    };
    if(index != -1) {
      reports[index] = newVersion;
    } else {
      reports.push(newVersion);
    }
    localStorage.setItem(STORAGE_REPORT_KEY, JSON.stringify(reports));
  },
  loadFromStorage(id: string): boolean {
    // Don't watch for changes when loading data from local storage
    enableWatch = false;
    const data = localStorage.getItem(STORAGE_REPORT_KEY);
    if(data) {
      const reports = JSON.parse(data) as PersistedReport[];
      const report = reports.find((item) => item.inspectionId === id);
      if(report) {
        this.inspectionId = id;
        this.constatations = report.constatations;
        this.photos = report.photos;
        this.refreshDownloadLinks().then(() => {
          enableWatch = true;
        });
        return true;
      }
    }
    enableWatch = true;
    return false;
  },
  async refreshDownloadLinks(): Promise<void[]> {
    const promises: Promise<void>[] = [];
    for(const photo of this.photos) {
      promises.push(getFileUrl(photo.remoteId).then((result) => {
        if(result) {
          photo.downloadUrl = result;
        } else {
          Logger.error(`report.ts : could not get url of photo ${JSON.stringify(photo)}`);
        }
      }));
    }
    return Promise.all(promises);
  }
});

// Persist any changes to report

const debouncedWatch = debounce((newReport: Report ) => {
  if(enableWatch && newReport.inspectionId) {
    newReport.persist();
    bc.postMessage(newReport.inspectionId);
  }
},
200,
{
  maxWait: 1000, // after 1s persist and send message no matter what user is doing
});

watch(
  () => report,
  (newReport) => {
    if(enableWatch) {
      debouncedWatch(newReport); // debounce call to concatenate several changes
    }
  },
  { deep: true }
);
