import { Ref } from "vue";
import { InjectionKey } from "vue";

export type LoaderData = {
  active: Ref<boolean>;
  message: Ref<string>;
  toggle: () => void;
  reset: () => void;
}

export const LoaderSymbol = Symbol(
  "Loader provider identifier"
) as InjectionKey<LoaderData>;
