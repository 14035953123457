import { IQOA_MARK, IQOA_MARK_S } from "@/states/report";
import { reactive } from "vue";

export interface CatalogueItem {
  sectionId: number;
  catalogueId: number;
  num: number;
  title: string;
  description: string;
  part: string;
  subPart: string;
  subDescription: string;
  mark: string;
};

export const cataloguesItems = reactive<CatalogueItem[]>([]);

export function getMarkInReport(mark: string): IQOA_MARK {
  const c_mark = mark.toUpperCase();
  if(c_mark === "1" || c_mark === "2" || c_mark === "2E" || c_mark === "3" || c_mark === "3U") return c_mark;
  return "1";
}
/**
 * Returns the worst mark in an array of mark
 * @param marks
 * @returns
 */
export function getWorstMark(marks: IQOA_MARK[]): IQOA_MARK {
  if(marks.length === 0) return "1"; // return 1 when there is no constatations
  // Check marks in descending orders
  else if(marks.find((mark) => mark ==="3U")) return "3U";
  else if(marks.find((mark) => mark ==="3")) return "3";
  else if(marks.find((mark) => mark ==="2E")) return "2E";
  else if(marks.find((mark) => mark ==="2")) return "2";
  else if(marks.find((mark) => mark ==="1")) return "1";
  else if(marks.find((mark) => mark ==="NC")) return "NC";
  throw new Error("cannot get worst mark");
}

// If 1 mark S is true, then the whole is
export function getWorstMarkS(markS: boolean[]): IQOA_MARK_S {
  const mark = markS.some((mark) => mark);
  return exportSMark(mark);
}

function exportSMark(mark: boolean): IQOA_MARK_S {
  if(mark) return "S";
  else return "";
}

/*[
  {
    id: "311100",
    sectionId: "3111",
    num: "1110",
    description: "Fissuration du terrain (ou de la chaussée) parallèlement au mur",
    subDescription: "Fissuration discontinue",
    mark: "1",
  },
  {
    id: "311101",
    sectionId: "3111",
    num: "1110",
    description: "Fissuration du terrain (ou de la chaussée) parallèlement au mur",
    subDescription: "Fissuration continue - sans décalage verticale",
    mark: "2E",
  },
  {
    id: "311102",
    sectionId: "3111",
    num: "1110",
    description: "Fissuration du terrain (ou de la chaussée) parallèlement au mur",
    subDescription: "Fissuration discontinue - avec décalage verticale",
    mark: "3",
  },
  {
    id: "311190",
    sectionId: "3111",
    num: "",
    description: "Autre défaut",
    subDescription: "",
    mark: "1",
  },
];*/
