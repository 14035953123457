<script setup lang="ts">
import CatalogueComponent from '@/components/catalogue/CatalogueComponent.vue';
import { inject, onBeforeMount, ref } from 'vue';
import { LoaderSymbol } from '@/components/shared/loader.type';
import { loadCataloguesfromXlsx } from '@/catalogues/loader';
import { signIn } from '@/microsoft/auth';
import { report } from '@/states/report';
import { Logger } from '@/logger';
import { useRoute } from 'vue-router';

const route = useRoute();
const loader = inject(LoaderSymbol);


// FIXME : how to properly type route and route params ?
const inspectionId = ref<string>(route.params.inspectionid)

async function signInAndLoad () {
  await signIn();
  if(loader) {
    loader.message.value = "Chargement de vos données";
    loader.active.value = true;
  }
  await loadCataloguesfromXlsx();
  if(loader) {
    loader.message.value = "";
    loader.active.value = false;
  }
}

onBeforeMount(async () => {
  await signInAndLoad();
  if (!report.loadFromStorage(inspectionId.value)) {
    Logger.warn(`CatalogueSearchView.vue : could not load report from storage`);
  }
});
</script>
<template>
  <div class="container-fluid mt-1">
    <CatalogueComponent :inspectionid="inspectionId" />
  </div>
</template>
