import * as XLSX from 'xlsx';
import { getCatalogueFile } from '@/microsoft/graph';
import { CatalogueItem, cataloguesItems } from './item';
import { Catalogue, catalogues } from './catalogue';
import { catalogueSections, CatalogueSection } from './section';
import { Logger } from '@/logger';

interface CatalogueItemRaw {
    Catalogue: string;
    Types: number;
    Classe: number| string;
    "Titre": string;
    Description: string;
    "Sous-description": string;
    "N° Désordre": number;
    "N° sous sous partie": number;
    "Observations, Commentaires, croquis": string;
    "Origines": string;
    "Partie": string;
    "Sous-partie": string;
    "Sous sous partie": string;
}

export let raws: CatalogueItemRaw[] = [];

let isRawLoaded = false;

export async function loadCataloguesfromXlsx() {
    if(isRawLoaded) return;
    try {
        const file = await getCatalogueFile();
        // Fetch the file from the URL
        const response = await fetch(file["@microsoft.graph.downloadUrl"]);

        if (!response.ok) {
            throw new Error(`Failed to fetch the file: ${response.statusText}`);
        }

        // Convert the response to an ArrayBuffer
        const arrayBuffer = await response.arrayBuffer();

        // Read the XLSX file
        const workbook = XLSX.read(arrayBuffer, { type: 'array' });
        // Extract data from each table
        const opts: XLSX.Sheet2JSONOpts = {
            blankrows: false,
        };
        raws = XLSX.utils.sheet_to_json(workbook.Sheets["Desordres"], opts);
        for(const raw of raws) {
            clean(raw)
            processRaw(raw);
        }
        isRawLoaded = true;
    } catch (error) {
        Logger.error(`Error fetching and processing the XLSX file: ${error}`);
    }
}

function processRaw(raw: CatalogueItemRaw) {
    try {
        const catalogueId = addCatalogue({
            num: raw.Types,
            description: raw.Catalogue,
        });
        const sectionId = addSection({
            description: raw.Partie,
            catalogueId: catalogueId,
        });

        addItem({
            catalogueId: catalogueId,
            sectionId: sectionId,
            num: raw['N° Désordre'],
            title: raw.Titre,
            description: raw.Description,
            subDescription: raw['Sous-description'],
            part: raw.Partie,
            subPart: raw['Sous sous partie'],
            mark: (typeof raw.Classe == "string") ? raw.Classe : raw.Classe.toString(),
        });
    } catch(error) {
        Logger.error(`loader.ts : Error processing element: ${error}, ${JSON.stringify(raw)}`);
    }

}

// add catalogue if it is not already in the array
// return catalog.num anyway
function addCatalogue(catalogue: Catalogue): number {
    const result = catalogues.findIndex((item) => item.num === catalogue.num);
    if(result == -1) {
        catalogues.push(catalogue);
    }
    return catalogue.num;
}

// add section if it is not already in the array
// return index anyway
function addSection(section: CatalogueSection) {
    const result = catalogueSections.findIndex((item) => {
       const sameDescription = item.description.trim().toLocaleLowerCase() === section.description.trim().toLocaleLowerCase();
       const sameCatalogue = item.catalogueId === section.catalogueId;
       return sameCatalogue && sameDescription;
    });
    if(result == -1) {
        catalogueSections.push(section);
        return catalogueSections.length -1; // index of the added element
    } else {
        return result;
    }
}

// Add the item to the list of items, no check
function addItem(catalogueItem: CatalogueItem) {
    cataloguesItems.push(catalogueItem);
}

function clean(raw: CatalogueItemRaw) {
    if(!raw.Description) raw.Description = "";
    if(!raw['Sous-description']) raw['Sous-description'] = "";
}

function downloadCatalogueToFile() {
    const jsonString = JSON.stringify(raws);
    const blob = new Blob([jsonString], { type: 'application/json' });
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = 'data.json';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(a.href);
}
