<script setup lang="ts">
import {generateReport, exportReportData } from "@/docx/generate";
import {PhotoItem, report} from "@/states/report";
import { getOuvrage, getInspection } from "@/xlsx/database";
import { inject } from "vue";
import { LoaderSymbol } from "../shared/loader.type";

const loader = inject(LoaderSymbol);
function checkPhotos() {
  const unLinkPhoto: PhotoItem[] = [];
  for(const photo of report.photos) {
    if(photo.type === "DEFAULT") { // ignore "VUE" as they normally aren't linked to constatations
      // Check if photo is in at least one constatation
      const isLinked = report.constatations.some((constatation) => {
        return constatation.photos.some((item) => item.id === photo.id);
      });
      // if not, keep photo ref
      if(!isLinked) unLinkPhoto.push(photo);
    }
  }
  // if there are photo with no links, alert user
  if(unLinkPhoto.length) return window.confirm(`Il y a des photos sans lien : ${unLinkPhoto.map((photo) => photo.order).join(", ")}. Continuer ?`);
  return true; // return true to continue if all photos have link
}

async function downloadReport() {
  if(!checkPhotos()) return;
  if(loader) {
    loader.active.value = true;
    loader.message.value = "Génération du rapport en cours..."
  }
   // Create a Blob from the file content
  const inspection = getInspection(report.inspectionId);
  const reportExport = await exportReportData(report);
  console.log(reportExport);
  const blob = await generateReport(reportExport, inspection.type);

  // Create a link element
  const link = document.createElement('a');

  // Create an object URL for the Blob
  link.href = URL.createObjectURL(blob);

  // Set the download attribute with a filename
  let filename = "";
  try {
    const ouvrage = getOuvrage(report.inspectionId);
    filename = ouvrage.name + ".docx";
  } catch(e) {
    console.error(e);
  } finally {
    link.download = filename;

    // Append the link to the body (required for Firefox)
    document.body.appendChild(link);

    // Programmatically click the link to trigger the download
    link.click();
    if(loader) {
      loader.active.value = false;
      loader.message.value = ""
    }

    // Remove the link from the document
    document.body.removeChild(link);
  }
}
</script>
<template>
  <button @click="downloadReport()" class="btn btn-sm btn-primary z-1"><i class="bi bi-file-earmark-arrow-down"></i></button>
</template>
