<script setup lang="ts">
import router from "@/router";
import favicoUrl from "/favicon.ico";
import { signIn } from "@/microsoft/auth";

async function open() {
  await signIn();
  router.push({ name: "home" });
}

</script>
<template>
  <div class="px-4 py-5 my-5 text-center">
    <img class="d-block mx-auto mb-4" :src="favicoUrl" alt="" width="100">
    <h1 class="display-5 fw-bold text-body-emphasis">DR Rapports</h1>
    <div class="col-lg-6 mx-auto">
      <p class="lead mb-4">Vous êtes sur le point de tester un premier prototype de l'application DR Rapport. Cette application permet de bâtir des rapports d'inspection d'ouvrages d'art à l'aide d'une interface simple, rapide et efficace.</p>
      <div class="d-grid gap-2 d-sm-flex justify-content-sm-center">
        <button @click="open" type="button" class="btn btn-primary btn-lg px-4 gap-3">Se
          connecter</button>
      </div>
    </div>
  </div>
</template>
