<script setup lang="ts">
import router from '@/router';
import { ref } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();
const searchUrl = `/report/${route.params.inspectionid}/catalogue-search`;
// open search in a new tab
function openSearch() {
  const routeData = router.resolve({
    name: "catalogue-search",
    params: {
      inspectionid: route.params.inspectionid,
    }
  });
  window.open(routeData.href, "_blank");
}
</script>
<template>
  <div class="input-group d-flex align-items-center">
    <input type="text" class="form-control" placeholder="Rechercher dans le catalogue" data-bs-toggle="modal"
      data-bs-target="#search-catalog-modal" style="max-width: 150px;" />
      <a :href="searchUrl" target="_blank" rel="noopener noreferrer"><span class="input-group-text">
      <i class="bi bi-fullscreen"></i></span></a>
  </div>
</template>
