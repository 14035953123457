import { Configuration, PopupRequest, PublicClientApplication, SilentRequest, InteractionRequiredAuthError } from "@azure/msal-browser";

const MSAL_KEY = "msalAccount";

export const msalConfig: Configuration = {
  auth: {
    clientId: import.meta.env.DR_MICROSOFT_APP_ID,
    authority: `https://login.microsoftonline.com/${import.meta.env.DR_MICROSOFT_DIR_ID}`,
    redirectUri: import.meta.env.DR_REDIRECT_URL,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};

const msalRequest: PopupRequest  = { scopes: [] };

export function ensureScope (scope: string) {
    if (!msalRequest.scopes.some((s) => s.toLowerCase() === scope.toLowerCase())) {
        msalRequest.scopes.push(scope);
    }
}

const msalClient =  new PublicClientApplication(msalConfig);

// Log the user in
 export async function signIn() {
  await msalClient.initialize();
  const authResult = await msalClient.loginPopup(msalRequest);
  localStorage.setItem('msalAccount', authResult.account.username);
}

//Get token from Graph
export async function getToken() {
    let account = localStorage.getItem(MSAL_KEY);
    if (!account) {
        throw new Error(
            'User info cleared from session. Please sign out and sign in again.');
    }
    try {
        // First, attempt to get the token silently
        const silentRequest: SilentRequest = {
            scopes: msalRequest.scopes,
            account: msalClient.getAccountByUsername(account),
        };

        const silentResult = await msalClient.acquireTokenSilent(silentRequest);
        return silentResult.accessToken;
    } catch (silentError) {
        // If silent requests fails with InteractionRequiredAuthError,
        // attempt to get the token interactively
        if (silentError instanceof InteractionRequiredAuthError) {
            const interactiveResult = await msalClient.acquireTokenPopup(msalRequest);
            return interactiveResult.accessToken;
        } else {
            throw silentError;
        }
    }
}

// Check if an account was stored
export function tokenExist(): boolean {
    if(localStorage.getItem(MSAL_KEY)) {
        return true;
    } else {
        return false;
    }
}

export function clear() {
    localStorage.removeItem(MSAL_KEY);
}

