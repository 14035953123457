<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref, nextTick } from "vue";
import CatalogueComponent from "./CatalogueComponent.vue";
const input = ref<HTMLInputElement | null>(null);
const modal = ref<HTMLDivElement | null>(null);

function focusInput() {
  nextTick(() => {
    input.value?.focus();
  });
}

onMounted(() => {
  if(modal.value) {
    modal.value.addEventListener('show.bs.modal', focusInput);
  }
})

onBeforeUnmount(() => {
  modal.value?.removeEventListener('show.bs.modal', focusInput);
})

</script>
<template>
  <div ref="modal" class="modal" id="search-catalog-modal" tabindex="-1" aria-labelledby="search-catalog-label" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-scrollable">
      <div class="modal-content dr-modal">
        <div class="modal-body">
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          <CatalogueComponent />
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.dr-modal {
  min-height: 50vh;
}
</style>
