import * as XLSX from 'xlsx';
import { PhotoItem } from '@/states/report';
import { OldReportExport } from '@/docx/export.config';
import { getDatabaseUrl, getFigureFromExtract } from '@/microsoft/graph';
import { Logger } from '@/logger';

export interface Ouvrage {
    id: string;
    name: string;
    process?: string;
    type: number[];
    // Add other properties as needed
}

interface OuvrageRaw {
    ID: string | number;
    Nom: string;
    "Nom du procédé"?: string;
    "Type": string | number;
    // Add other properties as needed
}

export interface Inspection {
    id: string;
    ouvrageId: string;
    meteo: string;
    temperature: string;
    partiesNonVisitees: string;
    moyens: string;
    type : "IDP" | "IQOA";
    date: number | "";
    dateFormated: string;
    done: "OUI" | "NON" | "PARTIELLEMENT";
    campaign: string;
    // Add other properties as needed
}

interface InspectionRaw {
    ID: string | number;
    "Ouvrage Id": string;
    "Météo": string;
    "Température": number | "";
    "Moyens": string;
    "Parties non visitées": string;
    Type : "IDP" | "IQOA";
    Date: number | "";
    "Fait ?": "OUI" | "NON" | "PARTIELLEMENT";
    Année: number | "";
    // Add other properties as needed
}

interface DesordreRaw {
    ID: string | number;
    "Inspection Id": string;
    Photo: string;
    "Partie de l'ouvrage": string;
    Localisation: string;
    "Commentaires": string;
    // Add other properties as needed
}

export interface VueGeneraleRaw {
    ID: string | number;
    "Inspection Id": string;
    Photo: string;
    Localisation: string;
    // Add other properties as needed
}

interface OldReportRaw {
    ID: string | number;
    Nom: string;
    District: string;
    Commune: string;
    "Maître d'ouvrage": string;
    "Service Gestionnaire": string;
    "Position à la voie": string;
    "Distance à la voie": string;
    "Autoroute": string;
    "PR": string;
    "Voie": string;
    "Sens": string;
    "BIS": string;
    "Accessibilité": string;
    "Plan de situation": string;
    "Vue Gmaps": string;
    "Matériau": string;
    "Fonctionnement": string;
    "Type": string;
    "Nom du procédé": string;
    "Fondations": string;
    "Hauteur mini": string;
    "Hauteur max. visible": string;
    "Longueur": string;
    "Surface": string;
    "Fruit": string;
    "Epaisseur": string;
    "Maitre d'ouvrage - Conception": string;
    "Année de construction": string;
    "Entreprise construtrice": string;
    "Date de mise en service": string;
    "Particularités": string;
    "Date Précédente Inspection": string;
    "Prochaine Inspection": string;
    "Campagne": string;
    "Note A N-1": string;
    "Note A N-1 S": string;
    "Note D N-1": string;
    "Note D N-1 S": string;
    "Note E N-1": string;
    "Note E N-1 S": string;
    "Note S N-1": string;
    "Note S N-1 S": string;
    "Note N-1": string;
    "Note N-1 S": string;
    latDeb: string;
    longDeb: string;
    latFin: string;
    longFin: string;
    "concGen N-1": string;
    "concSecu N-1": string;
    "concStruct N-1": string;
    "concDrain N-1": string;
    "concEquip N-1": string;
    "sugSecu N-1": string;
    "sugCour N-1": string;
    "sugSpe N-1": string;
    "sugInv N-1": string;
    "sugSurv N-1": string;
}

let ouvragesRaw: OuvrageRaw[] = [];
let inspectionsRaw: InspectionRaw[] = [];
let desordresRaw: DesordreRaw[] = [];
let vuesRaw: VueGeneraleRaw[] = [];
let oldReportRaws: OldReportRaw[] = [];
let isRawLoaded = false;
// Function to fetch and read the XLSX file from a URL
export async function fetchAndProcessXlsx(): Promise<void> {
    if(isRawLoaded) return;
    const url = await getDatabaseUrl();
    if(!url) return;
    try {
        // Fetch the file from the URL
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`Failed to fetch the file: ${response.statusText}`);
        }

        // Convert the response to an ArrayBuffer
        const arrayBuffer = await response.arrayBuffer();

        // Read the XLSX file
        const workbook = XLSX.read(arrayBuffer, { type: 'array' });
        const options: XLSX.Sheet2JSONOpts = {
            blankrows: false,
            defval:"",
        }
        // Extract data from each table
        ouvragesRaw = XLSX.utils.sheet_to_json(workbook.Sheets["Ouvrages"], options);
        inspectionsRaw = XLSX.utils.sheet_to_json(workbook.Sheets["Inspections"], options);
        desordresRaw = XLSX.utils.sheet_to_json(workbook.Sheets["Désordres"], options);
        vuesRaw = XLSX.utils.sheet_to_json(workbook.Sheets["Vue générale"], options);
        oldReportRaws = XLSX.utils.sheet_to_json(workbook.Sheets["dataOA"], options);
        isRawLoaded = true;
    } catch (error) {
        console.error(`Error fetching and processing the XLSX file: ${error}`);
    }
}

export function getOuvrages(): Ouvrage[] {
    let ouvrages: Ouvrage[] = [];
    for(const inspectionRaw of inspectionsRaw) {
        if(inspectionRaw['Fait ?'] && !ouvrages.some((ouvrage) => ouvrage.id === inspectionRaw["Ouvrage Id"])) {
            const ouvrageRaw = ouvragesRaw.find((ouvrageRaw) => ouvrageRaw.ID === inspectionRaw["Ouvrage Id"]);
            if(ouvrageRaw) {
                ouvrages.push(decodeOuvrageRaw(ouvrageRaw));
            }
        }
    }
    return ouvrages;
}

export function getInspections(): Inspection[] {
    return inspectionsRaw.map((inspection) => {
        return decodeInspectionRaw(inspection);
    });
}

// returns an array of all the inspection that are related to the ouvrage
export function getInspectionsByOuvrage(ouvragId: string): Inspection[] {
    const inspections: Inspection[] = [];
    inspectionsRaw.map((inspectionRaw) => {
        if(inspectionRaw['Ouvrage Id'] === ouvragId) {
            inspections.push(decodeInspectionRaw(inspectionRaw));
        }
    });

    return inspections;
}

export function getDesordresByInspection(inspectionId: string): PhotoItem[] {
    const desordres: PhotoItem[] = [];
    desordresRaw.map((desordreRaw) => {
        if(desordreRaw['Inspection Id'].toString() === inspectionId) {
            desordres.push(decodeDesordreRaw(desordreRaw));
        }
    });
    return desordres;
}

export function getVuesByInspection(inspectionId: string): PhotoItem[] {
    const vues: PhotoItem[] = [];
    vuesRaw.map((vueRaw) => {
        if(vueRaw['Inspection Id'].toString() === inspectionId) {
            vues.push(decodeVueRaw(vueRaw));
        }
    });
    return vues;
}
// returns the ouvrage that is related to the inspection, works with inspection id as well
export function getOuvrage(object: Inspection | string): Ouvrage {
    let inspection: Inspection;
    if(typeof object == "string") inspection = getInspection(object);
    else inspection = object;
    for(const ouvrageRaw of ouvragesRaw) {
        if(ouvrageRaw.ID === inspection.ouvrageId) {
            return decodeOuvrageRaw(ouvrageRaw);
        }
    }

    throw new Error(`database.ts : can't find ouvrage for inspection ${JSON.stringify(inspection)}`);
}

// Return the inspection that has the id argument
export function getInspection(id: string): Inspection {
    for(const inspection of inspectionsRaw) {
        if(inspection.ID == id) return decodeInspectionRaw(inspection);
    }
    throw new Error(`database.ts : can't find inspection for id ${id}`);
}

function decodeInspectionRaw(inspection: InspectionRaw): Inspection {
    return {
        id: inspection.ID.toString(),
        ouvrageId: inspection['Ouvrage Id'],
        meteo: inspection.Météo.replace(" ,",","),
        temperature: inspection.Température.toString(),
        partiesNonVisitees: inspection['Parties non visitées'],
        moyens: inspection.Moyens,
        type: inspection.Type,
        date: inspection.Date,
        dateFormated: decodeDate(inspection.Date),
        done: inspection['Fait ?'],
        campaign: inspection.Année.toString(),
    };
}

function decodeOuvrageRaw(ouvrage: OuvrageRaw): Ouvrage {

    return {
        id: ouvrage.ID.toString(),
        name: ouvrage.Nom,
        process: ouvrage['Nom du procédé'],
        type:  getOuvrageType(ouvrage.Type),
    }
}

function getOuvrageType(raw: OuvrageRaw["Type"]): number[] {
    if(typeof raw === "number") return [raw];
    const pattern = /(\d+)/gi;
    const matches = raw.match(pattern);
    if(matches) {
        return matches.map((match) => {
            return parseInt(match,10);
        });
    } else {
        return [];
    }
}

function decodeDesordreRaw(desordre: DesordreRaw): PhotoItem {
    // remove space before coma
    desordre.Commentaires.replace(" ,",",");
    return {
        id: desordre.ID.toString(),
        remoteId: "",
        type: "DEFAULT",
        downloadUrl: "",
        order: -1,
        inspectionId: desordre['Inspection Id'],
        photoUrl: desordre.Photo.replace("Désordres_Images/",""),
        part: desordre["Partie de l'ouvrage"],
        localization: desordre.Localisation,
        comment: desordre.Commentaires,
    };
}

function decodeVueRaw(vue: VueGeneraleRaw): PhotoItem {
    return {
        id: vue.ID.toString(),
        type: "VUE",
        remoteId: "",
        downloadUrl: "",
        order: -1,
        inspectionId: vue['Inspection Id'],
        photoUrl: vue.Photo.replace("VUE GENERALE_Images/",""),
        localization: vue.Localisation,
        comment: "Vue générale",
        part: "",
    }
}

export async function getOldReportExport(inspectionId: string): Promise<OldReportExport> {
    const ouvrage = getOuvrage(inspectionId);
    const oldReportRaw = getOldReportData(ouvrage.id);
    const oldReport = await decodeOldReportRaws(oldReportRaw);
    return oldReport;
}

// Returns the old data that has same Id as ouvrage Id,
function getOldReportData(ouvrageId: string): OldReportRaw {
    for(const oldReportRaw of oldReportRaws) {
        if(oldReportRaw.ID.toString() === ouvrageId) return oldReportRaw;
    }

    throw new Error(`database.ts : can't find old report data for ouvrage ${ouvrageId}`);
}

async function decodeOldReportRaws(oldReportRaw: OldReportRaw): Promise<OldReportExport> {
    let vueGen = "";
    let vueGmaps = "";
    try {
        vueGmaps = await getFigureFromExtract(oldReportRaw['Vue Gmaps']) || "";
        vueGen = await getFigureFromExtract(oldReportRaw['Plan de situation']) || "";
    } catch (e) {
        Logger.error(`database.ts : could not get figures from folder.`);
    } finally {
        return {
        voie: oldReportRaw.Voie.toString(),
        sens: oldReportRaw.Sens.toString(),
        BIS: oldReportRaw.BIS.toString(),
        PR: oldReportRaw.PR.toString(),
        latDeb: oldReportRaw.latDeb.toString(),
        longDeb: oldReportRaw.longDeb.toString(),
        latFin: oldReportRaw.latFin.toString(),
        longFin: oldReportRaw.longFin.toString(),
        autoroute: oldReportRaw.Autoroute.toString(),
        vueGmaps: vueGmaps,
        vueGen: vueGen,
        datePrecInspection: oldReportRaw['Date Précédente Inspection'].toString(),
        materiau: oldReportRaw.Matériau.toString(),
        fonctionnement: oldReportRaw.Fonctionnement.toString(),
        typeMur: oldReportRaw.Type.toString(),
        procede: oldReportRaw['Nom du procédé'].toString(),
        fondations: oldReportRaw.Fondations.toString(),
        longueur: oldReportRaw.Longueur.toString(),
        epaisseur: oldReportRaw.Epaisseur.toString(),
        hauteurMini: oldReportRaw['Hauteur mini'].toString(),
        hauteurMaxi: oldReportRaw['Hauteur max. visible'].toString(),
        surfaceVisibleCalc: oldReportRaw.Surface.toString(),
        fruit: oldReportRaw.Fruit.toString(),
        district: oldReportRaw.District.toString(),
        commune: oldReportRaw.Commune.toString(),
        positionVoie: oldReportRaw['Position à la voie'].toString(),
        distanceVoie: oldReportRaw['Distance à la voie'].toString(),
        acces: oldReportRaw.Accessibilité.toString(),
        oldReport : {
            cotAbords: oldReportRaw['Note A N-1'].toString(),
            cotAbordsS: oldReportRaw['Note A N-1 S'].toString(),
            cotDrain: oldReportRaw['Note D N-1'].toString(),
            cotDrainS: oldReportRaw['Note D N-1 S'].toString(),
            cotStruct: oldReportRaw['Note S N-1'].toString(),
            cotStructS: oldReportRaw['Note S N-1 S'].toString(),
            cotEquip: oldReportRaw['Note E N-1'].toString(),
            cotEquipS: oldReportRaw['Note E N-1 S'].toString(),
            cot: oldReportRaw['Note N-1'].toString(),
            cotS: oldReportRaw['Note N-1 S'].toString(),
            concGen: oldReportRaw['concGen N-1'].toString(),
            concSecu: oldReportRaw['concSecu N-1'].toString(),
            concStruc: oldReportRaw['concStruct N-1'].toString(),
            concDrain: oldReportRaw['concDrain N-1'].toString(),
            concEquip: oldReportRaw['concEquip N-1'].toString(),
            sugSecu: oldReportRaw['sugSecu N-1'].toString(),
            sugCour: oldReportRaw['sugCour N-1'].toString(),
            sugSpe: oldReportRaw['sugSpe N-1'].toString(),
            sugInv: oldReportRaw['sugInv N-1'].toString(),
            sugSurv: oldReportRaw['sugSurv N-1'].toString(),
        }
    }
    }



}

export function getBlankOldReportExport(): OldReportExport {
    return {
        voie: "",
        sens: "",
        BIS: "",
        PR: "",
        latDeb: "",
        longDeb: "",
        latFin: "",
        longFin: "",
        autoroute: "",
        vueGmaps: "",
        vueGen: "",
        datePrecInspection: "",
        materiau: "",
        fonctionnement: "",
        typeMur: "",
        procede: "",
        fondations: "",
        longueur: "",
        epaisseur: "",
        hauteurMini: "",
        hauteurMaxi: "",
        surfaceVisibleCalc: "",
        fruit: "",
        district: "",
        commune: "",
        positionVoie: "",
        distanceVoie: "",
        acces: "",
        oldReport : {
            cotAbords: "",
            cotAbordsS: "/",
            cotDrain: "",
            cotDrainS: "/",
            cotStruct: "",
            cotStructS: "/",
            cotEquip: "",
            cotEquipS: "/",
            cot: "",
            cotS: "/",
            concGen: "",
            concSecu: "",
            concStruc: "",
            concDrain: "",
            concEquip: "",
            sugSecu: "",
            sugCour: "",
            sugSpe: "",
            sugInv: "",
            sugSurv: "",
        }
    }
}


function decodeDate(date: number | ""): string {
    if(!date) return ""; // if date is falsish like 0 or "" return blanck string
  // Adjust for Excel's base date issue (adding 2 days for the shift, and 1 hour for the time difference)
  const adjustedDays = date - 2 - (1 / 24);

  // Base date: January 1, 1900
  const baseDate = new Date(1900, 0, 1); // Months are 0-indexed in JavaScript

  // Calculate the target date for whole days
  const targetDate = new Date(baseDate.getTime() + adjustedDays * 24 * 60 * 60 * 1000);

  // Format the date and time in French locale
  const formattedDate = targetDate.toLocaleDateString('fr-FR', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });

  /*const formattedTime = targetDate.toLocaleTimeString('fr-FR', {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  });*/

  return `${formattedDate}`;
}
